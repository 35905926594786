import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Lp } from "./screens/Lp";
import './screens/Lp/style.css';

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Lp />,
  },
]);

const DesktopApp = () => {
  return <RouterProvider router={router} />;
};

export default DesktopApp;