import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Iphone } from "./screens/Iphone";
import { Base } from "./screens/Base";
import './screens/Base/mobile.css';

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Iphone />,
  },
  {
    path: "/iphone-13-u38-14-1",
    element: <Iphone />,
  },
  {
    path: "/Base",
    element: <Base />,
  },
]);

const MobileApp = () => {
  return <RouterProvider router={router} />;
};

export default MobileApp;