import React from "react";
import { Frame } from "../../components/Frame";
import { HomeIndicator } from "../../components/HomeIndicator";
import { HugeIconArrows } from "../../components/HugeIconArrows";
import { Slider } from "../../components/Slider";
import { StatusBarIphone } from "../../components/StatusBarIphone";
import { Upload } from "../../components/Upload";
import { VuesaxBoldTick } from "../../components/VuesaxBoldTick";
import { VuesaxLinearImportWrapper } from "../../components/VuesaxLinearImportWrapper";
import "/src/mobile/screens/Base/mobile.css";

export const Base = () => {
  return (
    <div className="LP">
      <div className="div-4">
        <div className="overlap-12">
          <div className="separator" />

          <img className="gradient" alt="Gradient" src="/img/mobile/gradient.svg" />

          <div className="background-3">
            <div className="container-18">
              <div className="container-19">
                <div className="overlay-shadow-2">
                  <div className="container-20">
                    <div className="text-wrapper-50">Result</div>
                  </div>

                  <img
                    className="mask-group-6"
                    alt="Mask group"
                    src="/img/mobile/mask-group-3.svg"
                  />
                </div>

                <div className="container-21">
                  <p className="text-wrapper-51">
                    Together, we combined our skills in business and technology
                    to create summie
                  </p>

                  <p className="text-wrapper-52">
                    – the app that saves both time and money. We dream of making
                    financial knowledge and solutions accessible for everyone
                    and easy to understand, and summie is our way to make that
                    difference.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-22">
              <div className="group-24">
                <div className="overlap-13">
                  <div className="group-25">
                    <div className="overlap-group-8">
                      <div className="text-wrapper-53">Modern Finance,</div>

                      <img
                        className="made-simple-2"
                        alt="Made simple"
                        src="/img/mobile/made-simple-2.svg"
                      />
                    </div>
                  </div>

                  <img className="group-26" alt="Group" src="/img/mobile/image.png" />

                  <img
                    className="group-27"
                    alt="Group"
                    src="/img/mobile/group-1.png"
                  />

                  <img
                    className="group-28"
                    alt="Group"
                    src="/img/mobile/group-2-2x.png"
                  />

                  <img
                    className="group-29"
                    alt="Group"
                    src="/img/mobile/group-3-2x.png"
                  />

                  <img
                    className="group-30"
                    alt="Group"
                    src="/img/mobile/group-4.png"
                  />

                  <img
                    className="group-31"
                    alt="Group"
                    src="/img/mobile/group-5.png"
                  />

                  <img
                    className="group-32"
                    alt="Group"
                    src="/img/mobile/group-6.png"
                  />

                  <img
                    className="group-33"
                    alt="Group"
                    src="/img/mobile/group-7.png"
                  />

                  <img
                    className="group-34"
                    alt="Group"
                    src="/img/mobile/group-8.png"
                  />

                  <img
                    className="group-35"
                    alt="Group"
                    src="/img/mobile/group-9.png"
                  />

                  <img
                    className="group-36"
                    alt="Group"
                    src="/img/mobile/group-10.png"
                  />

                  <img
                    className="group-37"
                    alt="Group"
                    src="/img/mobile/group-11.png"
                  />

                  <img
                    className="group-38"
                    alt="Group"
                    src="/img/mobile/group-12.png"
                  />

                  <img
                    className="group-39"
                    alt="Group"
                    src="/img/mobile/group-13.png"
                  />

                  <img
                    className="group-40"
                    alt="Group"
                    src="/img/mobile/group-14.png"
                  />

                  <img
                    className="clip-path-group-2"
                    alt="Clip path group"
                    src="/img/mobile/clip-path-group-2x.png"
                  />

                  <img
                    className="group-41"
                    alt="Group"
                    src="/img/mobile/group-1073714129-2x.png"
                  />

                  <div className="group-42" />

                  <div className="group-43" />

                  <div className="group-44" />

                  <div className="text-wrapper-54">+25%</div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-23">
            <div className="union-wrapper">
              <img className="union-2" alt="Union" src="/img/mobile/union-2.svg" />
            </div>

            <div className="heading-wrapper">
              <div className="heading">
                <p className="upgrade-to-the-new-2">
                  Upgrade to the new way
                  <br />
                  of managing expenses
                </p>
              </div>
            </div>

            <div className="follow-3">
              <div className="border-3" />

              <img className="SVG-2" alt="Svg" src="/img/mobile/svg-2-2.svg" />

              <div className="text-wrapper-55">Coming soon</div>
            </div>
          </div>

          <div className="follow-4">
            <div className="border-4" />

            <img className="image-2" alt="Image" src="/img/mobile/image-7.png" />

            <img
              className="img-6"
              alt="Follow us on"
              src="/img/mobile/follow-us-on-instagram-for-updates.svg"
            />
          </div>

          <div className="follow-5">
            <div className="border-3" />

            <img className="SVG-2" alt="Svg" src="/img/mobile/svg-3.svg" />

            <div className="text-wrapper-56">Coming soon</div>
          </div>

          <div className="text-wrapper-57">hello@summie.com</div>

          <img className="logo-2" alt="Logo" src="/img/mobile/logo.svg" />
        </div>

        <div className="overlap-14">
          <img
            className="group-45"
            alt="Group"
            src="/img/mobile/group-1073714128-2.png"
          />

          <div className="rectangle-9" />

          <img className="blur-3" alt="Blur" src="/img/mobile/blur-1.svg" />

          <div className="follow-6">
            <div className="border-3" />

            <img className="SVG-2" alt="Svg" src="/img/mobile/svg.svg" />

            <div className="text-wrapper-55">Coming soon</div>
          </div>

          <div className="iphone-pro-2">
            <div className="overlap-15">
              <img className="screen-2" alt="Screen" src="/img/mobile/screen.png" />

              <div className="photo-transaction-2">
                <div className="overlap-16">
                  <div className="iphone-lements-2">
                    <HomeIndicator
                      className="home-indicator-4"
                      device="i-phone"
                      homeIndicatorClassName="home-indicator-5"
                      orientation="portrait"
                    />
                    <StatusBarIphone
                      background={false}
                      cap="/img/mobile/wifi.png"
                      capClassName="status-bar-10"
                      capacityClassName="status-bar-9"
                      cellularConnection="/img/mobile/cellular-connection-2.svg"
                      cellularConnectionClassName="status-bar-12"
                      className="status-bar-6"
                      overlapGroupClassName="status-bar-8"
                      timeClassName="status-bar-7"
                      wifi="/img/mobile/wifi.png"
                      wifiClassName="status-bar-11"
                    />
                  </div>

                  <div className="frame-85">
                    <div className="overlap-17">
                      <div className="rectangle-10" />

                      <div className="rectangle-11" />

                      <Upload
                        BACKClassName="upload-24"
                        THREEButtonsClassName="upload-23"
                        THREEButtonsWrapperClassName="upload-22"
                        className="upload-16"
                        divClassName="upload-20"
                        divClassName1="upload-26"
                        divClassName2="upload-26"
                        divClassName3="upload-26"
                        divClassNameOverride="upload-21"
                        frame="/img/mobile/frame-6.svg"
                        frame1="/img/mobile/wifi.png"
                        frame2="/img/mobile/wifi.png"
                        frameClassName="upload-18"
                        frameClassName1="upload-25"
                        frameClassName2="upload-28"
                        frameClassName3="upload-30"
                        frameClassName4="upload-31"
                        frameClassNameOverride="upload-19"
                        img="/img/mobile/wifi.png"
                        imgClassName="upload-27"
                        imgClassNameOverride="upload-29"
                        overlapGroupClassName="upload-17"
                        property1="default"
                      />
                      <Slider
                        className="slider-4"
                        hasRectangle={false}
                        property1="default"
                        rectangleClassName="slider-5"
                        rectangleClassNameOverride="slider-6"
                      />
                      <div className="frame-86">
                        <div className="frame-87">
                          <HugeIconArrows />
                          <div className="text-wrapper-58">Back</div>
                        </div>
                      </div>

                      <div className="receipt-card-2">
                        <div className="header-text-2">
                          <div className="text-wrapper-59">Upload Success!</div>

                          <p className="text-wrapper-60">
                            Your upload has been successfully done.
                          </p>
                        </div>

                        <img
                          className="line-2"
                          alt="Line"
                          src="/img/mobile/line.svg"
                        />

                        <div className="amount-2">
                          <div className="text-wrapper-61">Your Receipt</div>

                          <div className="text-wrapper-62">EUR 1.000.000</div>
                        </div>

                        <div className="payment-details-2">
                          <div className="row-2">
                            <div className="payment-detail-2">
                              <div className="text-wrapper-63">Ref Number</div>

                              <div className="text-wrapper-64">
                                000085752257
                              </div>

                              <div className="text-wrapper-65">Ref Number</div>

                              <div className="text-wrapper-64">
                                000085752257
                              </div>

                              <div className="text-wrapper-65">Ref Number</div>

                              <div className="text-wrapper-64">
                                000085752257
                              </div>
                            </div>

                            <div className="payment-detail-2">
                              <div className="text-wrapper-63">
                                Payment Time
                              </div>

                              <div className="text-wrapper-64">
                                25 Feb 2023, 13:22
                              </div>
                            </div>
                          </div>

                          <div className="row-2">
                            <div className="payment-detail-2">
                              <div className="text-wrapper-63">
                                Payment Method
                              </div>

                              <div className="text-wrapper-66">
                                Bank Transfer
                              </div>
                            </div>

                            <div className="payment-detail-2">
                              <div className="text-wrapper-63">Sender Name</div>

                              <div className="text-wrapper-66">
                                Name Surname
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="vuesax-bold-tick-wrapper">
                          <VuesaxBoldTick />
                        </div>

                        <div className="PDF-button-2">
                          <VuesaxLinearImportWrapper />
                          <div className="text-wrapper-67">Get PDF Receipt</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rectangle-12" />

                  <div className="frame-88">
                    <div className="frame-89">
                      <div className="overlap-group-9">
                        <div className="text-wrapper-68">Albert Heijn</div>

                        <div className="frame-90">
                          <img
                            className="frame-91"
                            alt="Frame"
                            src="/img/mobile/frame-1073714143-1.svg"
                          />

                          <img
                            className="frame-91"
                            alt="Frame"
                            src="/img/mobile/frame-1073714141.svg"
                          />

                          <img
                            className="frame-91"
                            alt="Frame"
                            src="/img/mobile/frame-1073714140-2.svg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="frame-92">
                      <div className="frame-93">
                        <div className="text-wrapper-69">Date</div>

                        <div className="text-wrapper-70">12.04.2024</div>
                      </div>

                      <div className="frame-93">
                        <div className="text-wrapper-71">Accounting</div>

                        <img
                          className="img-6"
                          alt="Frame"
                          src="/img/mobile/frame-1073714128-2.svg"
                        />
                      </div>

                      <div className="frame-93">
                        <div className="text-wrapper-69">Serial Number</div>

                        <div className="text-wrapper-70">47478393</div>
                      </div>
                    </div>

                    <div className="frame-94">
                      <div className="frame-95">
                        <div className="text-wrapper-71">Totaal</div>

                        <div className="text-wrapper-72">€20</div>
                      </div>
                    </div>
                  </div>

                  <HomeIndicator
                    className="home-indicator-6"
                    device="i-phone"
                    homeIndicatorClassName="home-indicator-7"
                    orientation="portrait"
                  />
                  <Frame
                    archiveClassName="frame-101"
                    archiveClassNameOverride="frame-106"
                    className="frame-96"
                    divClassName="frame-100"
                    divClassName1="frame-104"
                    divClassName2="frame-107"
                    divClassName3="frame-104"
                    divClassNameOverride="frame-102"
                    frame="/img/mobile/frame-11.svg"
                    frame1="/img/mobile/frame-10.svg"
                    frame2="/img/mobile/frame-13.svg"
                    frame3="/img/mobile/frame-14-2.svg"
                    frameClassName="frame-97"
                    frameClassName1="frame-102"
                    frameClassName2="frame-103"
                    frameClassName3="frame-109"
                    frameClassNameOverride="frame-99"
                    img="/img/mobile/frame-12.svg"
                    imgClassName="frame-103"
                    imgClassNameOverride="frame-105"
                    settingsClassName="frame-108"
                    transactionsClassName="frame-98"
                  />
                </div>
              </div>

              <img className="silver-2" alt="Silver" src="/img/mobile/silver.png" />
            </div>
          </div>

          <div className="container-24">
            <div className="container-25">
              <div className="overlap-18">
                <div className="horizontal-divider" />

                <div className="container-26">
                  <div className="overlap-19">
                    <div className="div-5">
                      <div className="container-27">
                        <div className="text-wrapper-73">Export your files</div>
                      </div>

                      <div className="container-28">
                        <div className="text-wrapper-74">for easy sharing</div>
                      </div>

                      <div className="vertical-divider-2" />
                    </div>

                    <img
                      className="frame-110"
                      alt="Frame"
                      src="/img/mobile/frame-22.svg"
                    />
                  </div>
                </div>

                <div className="container-29">
                  <div className="container-30">
                    <div className="text-wrapper-75">Data security focus</div>
                  </div>

                  <div className="container-31">
                    <div className="text-wrapper-76">
                      your privacy, fully protected
                    </div>
                  </div>

                  <img
                    className="frame-111"
                    alt="Frame"
                    src="/img/mobile/frame-23.svg"
                  />
                </div>
              </div>

              <div className="div-5">
                <div className="overlap-19">
                  <div className="div-5">
                    <div className="container-32">
                      <div className="receipt-capture-2">
                        Receipt capture &amp; storage
                      </div>
                    </div>

                    <div className="container-33">
                      <p className="text-wrapper-77">all in one secure place</p>
                    </div>

                    <div className="vertical-divider-2" />
                  </div>

                  <img
                    className="frame-112"
                    alt="Frame"
                    src="/img/mobile/frame-15-2.svg"
                  />
                </div>
              </div>

              <div className="container-34">
                <div className="overlap-19">
                  <img
                    className="frame-113"
                    alt="Frame"
                    src="/img/mobile/frame-16.svg"
                  />

                  <div className="div-5">
                    <div className="container-35">
                      <div className="text-wrapper-73">
                        Organize &amp; track
                      </div>
                    </div>

                    <div className="container-36">
                      <div className="text-wrapper-74">
                        easy expense management
                      </div>
                    </div>

                    <div className="vertical-divider-2" />
                  </div>
                </div>
              </div>

              <div className="container-37">
                <div className="container-38">
                  <div className="text-wrapper-73">Intuitive design</div>
                </div>

                <div className="container-39">
                  <div className="text-wrapper-74">and simple to navigate</div>
                </div>

                <img
                  className="frame-114"
                  alt="Frame"
                  src="/img/mobile/frame-21.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overlap-20">
          <img
            className="frame-115"
            alt="Frame"
            src="/img/mobile/frame-1073714006-3x.png"
          />

          <img className="blur-4" alt="Blur" src="/img/mobile/blur.svg" />

          <div className="margin">
            <div className="container-40">
              <div className="container-41">
                <div className="heading-margin">
                  <p className="save-time-and-money-2">
                    Save time and money
                    <br />
                    with summie.
                  </p>
                </div>

                <div className="margin-2">
                  <div className="container-42">
                    <p className="text-wrapper-78">
                      We simplify your finances by keeping everything digital
                      and organized, saving you time and money.
                    </p>
                  </div>
                </div>
              </div>

              <div className="follow-3">
                <div className="border-3" />

                <img className="SVG-2" alt="Svg" src="/img/mobile/svg-1-2.svg" />

                <div className="text-wrapper-55">Coming soon</div>
              </div>
            </div>
          </div>

          <img
            className="iphone-pro-mockup-2"
            alt="Iphone pro mockup"
            src="/img/mobile/iphone-14-pro-mockup-1-2.png"
          />
        </div>

        <div className="container-43">
          <div className="frame-116">
            <div className="overlap-21">
              <div className="background-4">
                <div className="heading-margin-2">
                  <div className="div-6">
                    <div className="text-wrapper-79">Save time</div>
                  </div>
                </div>

                <div className="container-44">
                  <p className="text-wrapper-80">
                    No more paper clutter or manual tracking. summie keeps
                    everything digital, organized, and is designed to be
                    intuitive.
                  </p>
                </div>

                <img
                  className="mask-group-7"
                  alt="Mask group"
                  src="/img/mobile/mask-group.svg"
                />
              </div>

              <div className="archive-4">
                <img
                  className="frame-117"
                  alt="Frame"
                  src="/img/mobile/frame-1073714151.svg"
                />

                <div className="overlap-group-10">
                  <div className="rectangle-13" />

                  <Frame
                    archiveClassName="frame-123"
                    archiveClassNameOverride="frame-128"
                    className="frame-118"
                    divClassName="frame-122"
                    divClassName1="frame-126"
                    divClassName2="frame-130"
                    divClassName3="frame-133"
                    divClassNameOverride="frame-124"
                    frame="/img/mobile/wifi.png"
                    frame1="/img/mobile/wifi.png"
                    frame2="/img/mobile/wifi.png"
                    frame3="/img/mobile/wifi.png"
                    frameClassName="frame-119"
                    frameClassName1="frame-124"
                    frameClassName2="frame-129"
                    frameClassName3="frame-132"
                    frameClassNameOverride="frame-121"
                    img="/img/mobile/wifi.png"
                    imgClassName="frame-125"
                    imgClassNameOverride="frame-127"
                    settingsClassName="frame-131"
                    transactionsClassName="frame-120"
                  />
                </div>

                <div className="frame-134">
                  <div className="text-wrapper-81">Date</div>

                  <img
                    className="vector-2"
                    alt="Vector"
                    src="/img/mobile/vector-175-3.svg"
                  />
                </div>

                <div className="frame-135">
                  <div className="text-wrapper-81">Amount</div>

                  <img
                    className="vector-2"
                    alt="Vector"
                    src="/img/mobile/vector-175-1-2.svg"
                  />
                </div>

                <div className="frame-136">
                  <div className="text-wrapper-81">Seller</div>

                  <img
                    className="vector-2"
                    alt="Vector"
                    src="/img/mobile/vector-175-2.svg"
                  />
                </div>

                <img
                  className="frame-137"
                  alt="Frame"
                  src="/img/mobile/frame-1073714159.svg"
                />

                <div className="text-wrapper-82">Select</div>
              </div>
            </div>
          </div>

          <div className="frame-116">
            <div className="overlap-21">
              <div className="background-4">
                <div className="heading-margin-2">
                  <div className="div-6">
                    <div className="text-wrapper-79">Save money</div>
                  </div>
                </div>

                <div className="container-44">
                  <p className="text-wrapper-80">
                    Manage your financial admin efficiently. summie offers its
                    services at a competitive price, saving you money wherever
                    you go.
                  </p>
                </div>

                <img
                  className="mask-group-7"
                  alt="Mask group"
                  src="/img/mobile/mask-group-1.svg"
                />
              </div>

              <div className="archive-4">
                <div className="frame-138">
                  <div className="frame-139">
                    <div className="frame-140">
                      <img
                        className="frame-141"
                        alt="Frame"
                        src="/img/mobile/frame-25.svg"
                      />

                      <div className="text-wrapper-83">REVENUE</div>
                    </div>

                    <div className="frame-142">
                      <div className="frame-143">
                        <div className="text-wrapper-84">€</div>

                        <div className="text-wrapper-84">12.929</div>
                      </div>

                      <div className="frame-144">
                        <div className="frame-145">
                          <div className="text-wrapper-85">Excl. VAT</div>

                          <div className="text-wrapper-86">€ 10,500</div>
                        </div>

                        <div className="frame-145">
                          <div className="text-wrapper-85">VAT</div>

                          <div className="text-wrapper-86">€ 2,429</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="frame-139">
                    <div className="frame-140">
                      <img
                        className="frame-141"
                        alt="Frame"
                        src="/img/mobile/frame-24.svg"
                      />

                      <div className="text-wrapper-83">EXPENSES</div>
                    </div>

                    <div className="frame-142">
                      <div className="frame-143">
                        <div className="text-wrapper-84">€</div>

                        <div className="text-wrapper-84">12.929</div>
                      </div>

                      <div className="frame-144">
                        <div className="frame-145">
                          <div className="text-wrapper-85">Excl. VAT</div>

                          <div className="text-wrapper-86">€ 10,500</div>
                        </div>

                        <div className="frame-145">
                          <div className="text-wrapper-85">VAT</div>

                          <div className="text-wrapper-86">€ 2,429</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overlap-group-10">
                  <div className="rectangle-13" />

                  <Frame
                    archiveClassName="frame-123"
                    archiveClassNameOverride="frame-128"
                    className="frame-118"
                    divClassName="frame-122"
                    divClassName1="frame-126"
                    divClassName2="frame-130"
                    divClassName3="frame-133"
                    divClassNameOverride="frame-124"
                    frame="/img/mobile/wifi.png"
                    frame1="/img/mobile/wifi.png"
                    frame2="/img/mobile/wifi.png"
                    frame3="/img/mobile/wifi.png"
                    frameClassName="frame-119"
                    frameClassName1="frame-124"
                    frameClassName2="frame-149"
                    frameClassName3="frame-150"
                    frameClassNameOverride="frame-146"
                    img="/img/mobile/wifi.png"
                    imgClassName="frame-147"
                    imgClassNameOverride="frame-148"
                    settingsClassName="frame-131"
                    transactionsClassName="frame-120"
                  />
                </div>

                <div className="frame-151">
                  <img
                    className="frame-152"
                    alt="Frame"
                    src="/img/mobile/frame-1073714143-2.svg"
                  />

                  <img
                    className="frame-152"
                    alt="Frame"
                    src="/img/mobile/frame-1073714141-1-2.svg"
                  />

                  <img
                    className="img-6"
                    alt="Frame"
                    src="/img/mobile/frame-1073714162.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-45">
          <div className="overlay-shadow-2">
            <div className="container-20">
              <div className="text-wrapper-50">Our motto</div>
            </div>

            <img
              className="mask-group-8"
              alt="Mask group"
              src="/img/mobile/mask-group-2-3.svg"
            />
          </div>

          <div className="heading-2">
            <div className="text-wrapper-87">Why summie</div>
          </div>

          <p className="in-today-s-fast">
            In today’s fast-paced world, managing finances shouldn’t slow you
            down. <br />
            We are here to simplify your financial admin, helping you save time
            and money with an intuitive, digital approach.
          </p>
        </div>

        <div className="container-46">
          <div className="heading-3">
            <p className="text-wrapper-88">The app that works for you</p>
          </div>

          <div className="container-47">
            <p className="text-wrapper-89">
              With summie, all your receipts and invoices are in one place.
              Effortlessly add and attach your receipt directly to your
              transaction. summie makes it simple for every entrepreneur,
              freelancer, or individual who wants to streamline their financial
              administration.
            </p>
          </div>

          <div className="overlay-shadow-3">
            <div className="container-20">
              <div className="text-wrapper-50">Features</div>
            </div>

            <img
              className="mask-group-9"
              alt="Mask group"
              src="/img/mobile/mask-group-4-2.svg"
            />
          </div>
        </div>

        <div className="section">
          <div className="container-48">
            <div className="container-49">
              <div className="container-50">
                <div className="container-51">
                  <div className="div-6">
                    <div className="div-6">
                      <p className="text-wrapper-90">
                        summie started with a simple idea: what if we could make
                        managing receipts easy? As entrepreneurs, we were tired
                        of the paper clutter and existing inefficient tools. We
                        knew we needed something better, not just for ourselves
                        but for every professional or individual who values
                        their time.
                      </p>
                    </div>

                    <div className="container-52">
                      <p className="text-wrapper-91">
                        summie started with a simple idea: what if we could make
                        managing receipts easy? As entrepreneurs, we were tir
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="frame-153">
          <div className="container-53">
            <div className="list-wrapper">
              <div className="list">
                <div className="div-wrapper-3">
                  <div className="text-wrapper-92">Privacy Policy</div>
                </div>

                <div className="div-wrapper-3">
                  <div className="item">·</div>
                </div>

                <div className="div-wrapper-3">
                  <div className="text-wrapper-92">Terms of Conditions</div>
                </div>
              </div>
            </div>

            <div className="div-wrapper-3">
              <p className="text-wrapper-92">
                summie B.V. All rights reserved.
              </p>
            </div>
          </div>
        </div>

        <div className="follow-7">
          <div className="border-3" />

          <img className="SVG-2" alt="Svg" src="/img/mobile/svg-3.svg" />

          <div className="text-wrapper-56">Coming soon</div>
        </div>

        <img
          className="group-46"
          alt="Group"
          src="/img/mobile/group-1073714096-2.svg"
        />
      </div>
    </div>
  );
};
