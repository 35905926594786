/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Upload = ({
  property1,
  className,
  overlapGroupClassName,
  frameClassName,
  frameClassNameOverride,
  frame = "/img/frame-5.svg",
  divClassName,
  divClassNameOverride,
  THREEButtonsWrapperClassName,
  THREEButtonsClassName,
  BACKClassName,
  frameClassName1,
  divClassName1,
  imgClassName,
  img = "/img/frame-6.png",
  frameClassName2,
  divClassName2,
  imgClassNameOverride,
  frame1 = "/img/frame-6.png",
  frameClassName3,
  divClassName3,
  frameClassName4,
  frame2 = "/img/frame-6.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div className={`upload ${state.property1} ${className}`}>
      {["default", "variant-3"].includes(state.property1) && (
        <div className={`overlap-group-2 ${overlapGroupClassName}`}>
          <div className={`frame-2 ${frameClassName}`}>
            <img
              className={`img ${frameClassNameOverride}`}
              alt="Frame"
              src={state.property1 === "variant-3" ? "/img/frame-9.svg" : frame}
            />

            <div className={`div-wrapper ${divClassName}`}>
              <div className={`text-wrapper-2 ${divClassNameOverride}`}>
                Upload New
              </div>
            </div>
          </div>

          <div
            className={`THREE-BUTTONS-wrapper ${THREEButtonsWrapperClassName}`}
            onClick={() => {
              dispatch("click");
            }}
          >
            <div className={`THREE-BUTTONS ${THREEButtonsClassName}`}>
              <div className={`BACK ${BACKClassName}`} />

              <div className={`frame-3 ${frameClassName1}`}>
                <div className={`text-wrapper-3 ${divClassName1}`}>
                  Scan with camera
                </div>

                <img
                  className={`frame-4 ${imgClassName}`}
                  alt="Frame"
                  src={img}
                />
              </div>

              <div className={`frame-5 ${frameClassName2}`}>
                <div className={`text-wrapper-3 ${divClassName2}`}>
                  Upload Photo
                </div>

                <img
                  className={`frame-6 ${imgClassNameOverride}`}
                  alt="Frame"
                  src={frame1}
                />
              </div>

              <div className={`frame-7 ${frameClassName3}`}>
                <div className={`text-wrapper-3 ${divClassName3}`}>
                  Upload File
                </div>

                <img
                  className={`frame-8 ${frameClassName4}`}
                  alt="Frame"
                  src={frame2}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {state.property1 === "variant-2" && (
        <>
          <div
            className="frame-9"
            onClick={() => {
              dispatch("click_844");
            }}
          >
            <img className="img" alt="Frame" src="/img/image.svg" />

            <div className="frame-10">
              <div className="text-wrapper-4">Upload New</div>
            </div>
          </div>

          <div className="frame-11">
            <div className="overlap-group-wrapper">
              <div className="overlap-group-3">
                <div className="frame-12">
                  <div className="text-wrapper-3">Scan with camera</div>

                  <img className="img" alt="Frame" src="/img/frame-14.svg" />
                </div>

                <div className="frame-13">
                  <div className="text-wrapper-3">Upload Photo</div>

                  <img className="img" alt="Frame" src="/img/frame-15.svg" />
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-3">Upload File</div>

                  <img className="img" alt="Frame" src="/img/frame-16-2.svg" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.property1 === "variant-2") {
    switch (action) {
      case "click_844":
        return {
          property1: "default",
        };
    }
  }

  switch (action) {
    case "click":
      return {
        ...state,
        property1: "variant-2",
      };
  }

  return state;
}

Upload.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  frame: PropTypes.string,
  img: PropTypes.string,
  frame1: PropTypes.string,
  frame2: PropTypes.string,
};
