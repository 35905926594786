/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldTickCircle1 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-tick-circle-1 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.82631 1.51282C5.14909 1.51282 2.15259 4.50932 2.15259 8.18654C2.15259 11.8638 5.14909 14.8603 8.82631 14.8603C12.5035 14.8603 15.5 11.8638 15.5 8.18654C15.5 4.50932 12.5035 1.51282 8.82631 1.51282ZM12.0163 6.65158L8.23234 10.4356C8.13891 10.529 8.01211 10.5824 7.87864 10.5824C7.74516 10.5824 7.61836 10.529 7.52493 10.4356L5.63627 8.54692C5.44273 8.35338 5.44273 8.03304 5.63627 7.8395C5.82981 7.64596 6.15014 7.64596 6.34368 7.8395L7.87864 9.37446L11.3089 5.94417C11.5025 5.75063 11.8228 5.75063 12.0163 5.94417C12.2099 6.1377 12.2099 6.45137 12.0163 6.65158Z"
        fill="#6C53B7"
      />
    </svg>
  );
};
