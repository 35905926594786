/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HugeIconArrowsOutlineDirectionLeft011 = ({ className }) => {
  return (
    <svg
      className={`huge-icon-arrows-outline-direction-left-01-1 ${className}`}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.41504 4.15857L6.25422 6.85959L8.41504 9.56061"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.810306"
      />
    </svg>
  );
};
