/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxLinearImport1 = ({ className }) => {
  return (
    <svg
      className={`vuesax-linear-import-1 ${className}`}
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.23096 6.65723L6.51231 7.93858L7.79366 6.65723"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.750793"
      />

      <path
        className="path"
        d="M6.51062 2.81323V7.90361"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.750793"
      />

      <path
        className="path"
        d="M10.5744 6.90747C10.5744 9.11981 9.07279 10.9117 6.57015 10.9117C4.0675 10.9117 2.56592 9.11981 2.56592 6.90747"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.750793"
      />
    </svg>
  );
};
