/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Slider = ({
  property1,
  className,
  rectangleClassName,
  rectangleClassNameOverride,
  hasRectangle = true,
}) => {
  return (
    <div className={`slider ${className}`}>
      <div
        className={`rectangle property-1-2-${property1} ${rectangleClassName}`}
      />

      <div
        className={`rectangle-2 property-1-3-${property1} ${rectangleClassNameOverride}`}
      />

      {hasRectangle && (
        <div className={`rectangle-3 property-1-4-${property1}`} />
      )}
    </div>
  );
};

Slider.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  hasRectangle: PropTypes.bool,
};
