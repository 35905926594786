
import React, { useState, useEffect } from 'react';
import DesktopApp from './desktop/index'; // Updated to point to index.jsx in desktop
import MobileApp from './mobile/App'; // Mobile points to App.jsx

const App = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 480);


  return (
    <div className="App">
      {isMobile ? <MobileApp /> : <DesktopApp />}
    </div>
  );
};

export default App;
