/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxLinearImport = ({ className }) => {
  return (
    <svg
      className={`vuesax-linear-import ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.27515 8.66943L9.0857 10.48L10.8962 8.66943"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.06087"
      />

      <path
        className="path"
        d="M9.08313 3.23779V10.4305"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.06087"
      />

      <path
        className="path"
        d="M14.8255 9.02319C14.8255 12.1492 12.7038 14.6812 9.16755 14.6812C5.63132 14.6812 3.50958 12.1492 3.50958 9.02319"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.06087"
      />
    </svg>
  );
};
