/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const HomeIndicator = ({
  device,
  orientation,
  className,
  homeIndicatorClassName,
}) => {
  return (
    <div className={`home-indicator ${device} ${className}`}>
      <div
        className={`div device-${device} ${orientation} ${homeIndicatorClassName}`}
      />
    </div>
  );
};

HomeIndicator.propTypes = {
  device: PropTypes.oneOf(["i-pad", "i-phone"]),
  orientation: PropTypes.oneOf(["landscape", "portrait"]),
};
