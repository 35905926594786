/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const StatusBarIphone = ({
  background,
  className,
  timeClassName,
  overlapGroupClassName,
  capacityClassName,
  capClassName,
  cap = "/img/cap.svg",
  wifiClassName,
  wifi = "/img/wifi.svg",
  cellularConnectionClassName,
  cellularConnection = "/img/cellular-connection.svg",
}) => {
  return (
    <div className={`status-bar-iphone background-${background} ${className}`}>
      <div className="time">
        <div className={`text-wrapper ${timeClassName}`}>9:41</div>
      </div>

      <div className="levels">
        <div className="battery">
          <div className={`overlap-group ${overlapGroupClassName}`}>
            <div className={`capacity ${capacityClassName}`} />
          </div>

          <img className={`cap ${capClassName}`} alt="Cap" src={cap} />
        </div>

        <img className={`wifi ${wifiClassName}`} alt="Wifi" src={wifi} />

        <img
          className={`cellular-connection ${cellularConnectionClassName}`}
          alt="Cellular connection"
          src={cellularConnection}
        />
      </div>
    </div>
  );
};

StatusBarIphone.propTypes = {
  background: PropTypes.bool,
  cap: PropTypes.string,
  wifi: PropTypes.string,
  cellularConnection: PropTypes.string,
};
