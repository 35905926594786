/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldTickCircle = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-tick-circle ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 23 24"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.649 2.56958C6.4531 2.56958 2.21906 6.80363 2.21906 11.9995C2.21906 17.1954 6.4531 21.4295 11.649 21.4295C16.8449 21.4295 21.079 17.1954 21.079 11.9995C21.079 6.80363 16.8449 2.56958 11.649 2.56958ZM16.1565 9.83064L10.8097 15.1774C10.6777 15.3094 10.4986 15.3849 10.31 15.3849C10.1214 15.3849 9.94218 15.3094 9.81017 15.1774L7.14149 12.5087C6.86802 12.2353 6.86802 11.7826 7.14149 11.5092C7.41496 11.2357 7.8676 11.2357 8.14106 11.5092L10.31 13.6781L15.1569 8.83107C15.4304 8.5576 15.8831 8.5576 16.1565 8.83107C16.43 9.10454 16.43 9.54774 16.1565 9.83064Z"
        fill="#6C53B7"
      />
    </svg>
  );
};
