/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { HugeIconArrowsOutlineDirectionLeft01 } from "../../icons/HugeIconArrowsOutlineDirectionLeft01";
import "./style.css";

export const HugeIconArrows = () => {
  return (
    <HugeIconArrowsOutlineDirectionLeft01 className="huge-icon-arrows-outline-direction-left-01" />
  );
};
