import React from "react";
import { Frame } from "../../components/Frame";
import { HomeIndicator } from "../../components/HomeIndicator";
import { Slider } from "../../components/Slider";
import { StatusBarIphone } from "../../components/StatusBarIphone";
import { Upload } from "../../components/Upload";
import { HugeIconArrowsOutlineDirectionLeft011 } from "../../icons/HugeIconArrowsOutlineDirectionLeft011";
import { VuesaxBoldTickCircle1 } from "../../icons/VuesaxBoldTickCircle1";
import { VuesaxLinearImport1 } from "../../icons/VuesaxLinearImport1";
import "./phone.css";

export const Iphone = () => {
  return (
    <div className="iphone">
      <div className="div-3">
        <div className="overlap">
          <img className="group" alt="Group" src="/img/mobile/group-1073714128.png" />

          <div className="rectangle-4" />

          <img className="blur" alt="Blur" src="/img/mobile/blur-2.svg" />

          <div className="iphone-pro">
            <div className="overlap-2">
              <img className="screen" alt="Screen" src="/img/mobile/screen-2x.png" />

              <div className="photo-transaction">
                <div className="overlap-3">
                  <div className="iphone-lements">
                    <HomeIndicator
                      className="home-indicator"
                      device="i-phone"
                      homeIndicatorClassName="home-indicator-instance"
                      orientation="portrait"
                    />
                  </div>

                  <div className="overlap-wrapper">
                    <div className="overlap-4">
                      <div className="rectangle-5" />

                      <div className="rectangle-6" />

                      <Upload
                        BACKClassName="upload-9"
                        THREEButtonsClassName="upload-8"
                        THREEButtonsWrapperClassName="upload-7"
                        className="upload-instance"
                        divClassName="upload-5"
                        divClassName1="upload-6"
                        divClassName2="upload-6"
                        divClassName3="upload-6"
                        divClassNameOverride="upload-6"
                        frame="/img/mobile/frame-23-2.svg"
                        frame1="/img/mobile/frame-1073714128-1.png"
                        frame2="/img/mobile/frame-1073714128-1.png"
                        frameClassName="upload-3"
                        frameClassName1="upload-10"
                        frameClassName2="upload-12"
                        frameClassName3="upload-14"
                        frameClassName4="upload-15"
                        frameClassNameOverride="upload-4"
                        img="/img/mobile/frame-1073714128-1.png"
                        imgClassName="upload-11"
                        imgClassNameOverride="upload-13"
                        overlapGroupClassName="upload-2"
                        property1="default"
                      />
                      <Slider
                        className="slider-instance"
                        hasRectangle={false}
                        property1="default"
                        rectangleClassName="slider-2"
                        rectangleClassNameOverride="slider-3"
                      />
                     
                      <div className="receipt-card">
                        <div className="header-text">
                          <div className="text-wrapper-9">Upload Success!</div>

                          <p className="p">
                            Your upload has been successfully done.
                          </p>
                        </div>

                        <img
                          className="line"
                          alt="Line"
                          src="/img/mobile/line-2.svg"
                        />

                        <div className="amount">
                          <div className="text-wrapper-10">Your Receipt</div>

                          <div className="text-wrapper-11">EUR 1.000.000</div>
                        </div>

                        <div className="payment-details">
                          <div className="row">
                            <div className="payment-detail">
                              <div className="text-wrapper-12">Ref Number</div>

                              <div className="text-wrapper-13">
                                000085752257
                              </div>

                              <div className="text-wrapper-14">Ref Number</div>

                              <div className="text-wrapper-13">
                                000085752257
                              </div>

                              <div className="text-wrapper-14">Ref Number</div>

                              <div className="text-wrapper-13">
                                000085752257
                              </div>
                            </div>

                            <div className="payment-detail">
                              <div className="text-wrapper-12">
                                Payment Time
                              </div>

                              <div className="text-wrapper-13">
                                25 Feb 2023, 13:22
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="payment-detail">
                              <div className="text-wrapper-12">
                                Payment Method
                              </div>

                              <div className="text-wrapper-15">
                                Bank Transfer
                              </div>
                            </div>

                            <div className="payment-detail">
                              <div className="text-wrapper-12">Sender Name</div>

                              <div className="text-wrapper-15">
                                Name Surname
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="success-icon">
                          <VuesaxBoldTickCircle1 className="vuesax-bold-tick" />
                        </div>

                        <div className="PDF-button">
                          <VuesaxLinearImport1 className="vuesax-linear-import-1" />
                          <div className="text-wrapper-16">Get PDF Receipt</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rectangle-7" />

                  <div className="frame-17">
                    <div className="frame-18">
                      <div className="overlap-group-4">
                        <div className="text-wrapper-17">Albert Heijn</div>

                        <div className="frame-19">
                          <img
                            className="frame-20"
                            alt="Frame"
                            src="/img/mobile/frame-1073714143-1-2.svg"
                          />

                          <img
                            className="frame-20"
                            alt="Frame"
                            src="/img/mobile/frame-1073714141-2.svg"
                          />

                          <img
                            className="frame-20"
                            alt="Frame"
                            src="/img/mobile/frame-1073714140-2-2.svg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="frame-21">
                      <div className="frame-22">
                        <div className="text-wrapper-18">Date</div>

                        <div className="text-wrapper-19">12.04.2024</div>
                      </div>

                      <div className="frame-22">
                        <div className="text-wrapper-20">Accounting</div>

                        <img
                          className="frame-23"
                          alt="Frame"
                          src="/img/mobile/frame-1073714128.svg"
                        />
                      </div>

                      <div className="frame-22">
                        <div className="text-wrapper-18">Serial Number</div>

                        <div className="text-wrapper-19">47478393</div>
                      </div>
                    </div>

                    <div className="frame-24">
                      <div className="frame-25">
                        <div className="text-wrapper-20">Totaal</div>

                        <div className="text-wrapper-21">€20</div>
                      </div>
                    </div>
                  </div>

                  <HomeIndicator
                    className="home-indicator-2"
                    device="i-phone"
                    homeIndicatorClassName="home-indicator-3"
                    orientation="portrait"
                  />
                  <Frame
                    archiveClassName="frame-28"
                    archiveClassNameOverride="frame-33"
                    className="frame-1073714143"
                    divClassName="frame-27"
                    divClassName1="frame-31"
                    divClassName2="frame-34"
                    divClassName3="frame-31"
                    divClassNameOverride="frame-29"
                    frame="/img/mobile/frame-28.svg"
                    frame1="/img/mobile/frame-27.svg"
                    frame2="/img/mobile/frame-30.svg"
                    frame3="/img/mobile/frame-31.svg"
                    frameClassName="frame-instance"
                    frameClassName1="frame-29"
                    frameClassName2="frame-30"
                    frameClassName3="frame-36"
                    frameClassNameOverride="frame-26"
                    img="/img/mobile/frame-29.svg"
                    imgClassName="frame-30"
                    imgClassNameOverride="frame-32"
                    settingsClassName="frame-35"
                    transactionsClassName="frame-1073714143-instance"
                  />
                </div>
              </div>

              <img className="silver" alt="Silver" src="/img/mobile/silver.png" />
            </div>
          </div>

          <div className="frame-37">
            <div className="container">
              <div className="overlap-5">
                <div className="overlap-group-5">
                  <div className="receipt-capture-wrapper">
                    <div className="receipt-capture">
                      Receipt capture &amp; storage
                    </div>
                  </div>

                  <div className="container-2">
                    <p className="text-wrapper-22">all in one secure place</p>
                  </div>
                </div>

                <img className="frame-38" alt="Frame" src="/img/mobile/frame-32.svg" />
              </div>
            </div>

            <div className="container">
              <div className="overlap-5">
                <img className="frame-39" alt="Frame" src="/img/mobile/frame-33.svg" />

                <div className="overlap-group-5">
                  <div className="organize-track-wrapper">
                    <div className="text-wrapper-23">Organize &amp; track</div>
                  </div>

                  <div className="container-3">
                    <div className="text-wrapper-24">
                      easy expense management
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="container-4">
                <div className="text-wrapper-23">Intuitive design</div>
              </div>

              <div className="container-5">
                <div className="text-wrapper-24">and simple to navigate</div>
              </div>

              <img className="frame-40" alt="Frame" src="/img/mobile/frame-34.svg" />
            </div>

            <div className="container">
              <div className="overlap-5">
                <div className="overlap-group-5">
                  <div className="container-6">
                    <div className="text-wrapper-23">Export your files</div>
                  </div>

                  <div className="container-7">
                    <div className="text-wrapper-24">for easy sharing</div>
                  </div>
                </div>

                <img className="frame-41" alt="Frame" src="/img/mobile/frame-35.svg" />
              </div>
            </div>

            <div className="container">
              <div className="container-8">
                <div className="text-wrapper-25">Data security focus</div>
              </div>

              <div className="container-9">
                <div className="text-wrapper-26">
                  your privacy, fully protected
                </div>
              </div>

              <img className="frame-42" alt="Frame" src="/img/mobile/frame-36.svg" />
            </div>
          </div>
        </div>

        <div className="overlap-6">
          <img
            className="frame-43"
            alt="Frame"
            src="/img/mobile/frame-1073714006.png"
          />

          <img className="blur-2" alt="Blur" src="/img/mobile/blur-1-2.svg" />

          <img
            className="group-2"
            alt="Group"
            src="/img/mobile/group-1073714096.svg"
          />
          <div className="follow-99">
            <div className="border-99" />

            <img className="apple-icon-99" alt="Svg" src="/img/mobile/apple-icon-99.svg" />

            <div className="text-wrapper-99">Coming soon</div>
          </div>

          <div className="container-10">
            <p className="save-time-and-money">
              Save time and money
              <br />
              with summie.
            </p>

            <p className="text-wrapper-27">
              We simplify your finances by keeping everything digital and
              organized, saving you time and money.
            </p>

            <div className="follow">
              <div className="border" />

              <img className="SVG" alt="Svg" src="/img/mobile/svg-2.svg" />

              <div className="text-wrapper-28">Coming soon</div>
            </div>
          </div>
        </div>

        <video 
          autoPlay
          muted
          playsInline
            className="iphone-pro-mockup"
            alt="Iphone pro mockup"
            >
            <source src="/img/desktop/LandingPageAnimation.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        <div className="frame-44">
          <div className="container-11">
            <div className="overlay-shadow">
              <div className="container-12">
                <div className="text-wrapper-29">Our motto</div>
              </div>

              <img
                className="mask-group"
                alt="Mask group"
                src="/img/mobile/mask-group-1-2.svg"
              />
            </div>

            <div className="text-wrapper-30">Why summie</div>

            <p className="text-wrapper-27">
              In today’s fast-paced world, managing finances shouldn’t slow you
              down. <br />
              We are here to simplify your financial admin, helping you save
              time and money with an intuitive, digital approach.
            </p>
          </div>

          <div className="container-13">
            <div className="frame-45">
              <div className="overlap-7">
                <div className="background">
                  <div className="frame-46">
                    <div className="text-wrapper-31">Save time</div>

                    <p className="text-wrapper-32">
                      No more paper clutter or manual tracking. summie keeps
                      everything digital, organized, and is designed to be
                      intuitive.
                    </p>
                  </div>

                  <img
                    className="mask-group-2"
                    alt="Mask group"
                    src="/img/mobile/mask-group-2-2.svg"
                  />
                </div>

                <div className="archive-2">
                  <img
                    className="frame-47"
                    alt="Frame"
                    src="/img/mobile/frame-1073714151-2.svg"
                  />

                  <div className="overlap-group-6">
                    <div className="rectangle-8" />

                    <Frame
                      archiveClassName="frame-53"
                      archiveClassNameOverride="frame-58"
                      className="frame-48"
                      divClassName="frame-52"
                      divClassName1="frame-56"
                      divClassName2="frame-60"
                      divClassName3="frame-63"
                      divClassNameOverride="frame-54"
                      frame="/img/mobile/frame-1073714128-1.png"
                      frame1="/img/mobile/frame-1073714128-1.png"
                      frame2="/img/mobile/frame-1073714128-1.png"
                      frame3="/img/mobile/frame-1073714128-1.png"
                      frameClassName="frame-49"
                      frameClassName1="frame-54"
                      frameClassName2="frame-59"
                      frameClassName3="frame-62"
                      frameClassNameOverride="frame-51"
                      img="/img/mobile/frame-1073714128-1.png"
                      imgClassName="frame-55"
                      imgClassNameOverride="frame-57"
                      settingsClassName="frame-61"
                      transactionsClassName="frame-50"
                    />
                  </div>

                  <div className="frame-64">
                    <div className="text-wrapper-33">Date</div>

                    <img
                      className="vector"
                      alt="Vector"
                      src="/img/mobile/vector-175.svg"
                    />
                  </div>

                  <div className="frame-65">
                    <div className="text-wrapper-33">Amount</div>

                    <img
                      className="vector"
                      alt="Vector"
                      src="/img/mobile/vector-175-1.svg"
                    />
                  </div>

                  <div className="frame-66">
                    <div className="text-wrapper-33">Seller</div>

                    <img
                      className="vector"
                      alt="Vector"
                      src="/img/mobile/vector-175-2-2.svg"
                    />
                  </div>

                  <img
                    className="frame-67"
                    alt="Frame"
                    src="/img/mobile/frame-1073714159-2.svg"
                  />

                  <div className="text-wrapper-34">Select</div>
                </div>
              </div>
            </div>

            <div className="frame-68">
              <div className="overlap-8">
                <div className="background">
                  <div className="frame-46">
                    <div className="text-wrapper-31">Save money</div>

                    <p className="text-wrapper-32">
                      Manage your financial admin efficiently. summie offers its
                      services at a competitive price, saving you money wherever
                      you go.
                    </p>
                  </div>

                  <img
                    className="mask-group-3"
                    alt="Mask group"
                    src="/img/mobile/mask-group-3-2.svg"
                  />
                </div>

                <div className="archive-3">
                  <div className="frame-69">
                    <div className="frame-70">
                      <div className="frame-71">
                        <img
                          className="frame-72"
                          alt="Frame"
                          src="/img/mobile/frame-42.svg"
                        />

                        <div className="text-wrapper-35">REVENUE</div>
                      </div>

                      <div className="frame-73">
                        <div className="frame-74">
                          <div className="text-wrapper-36">€</div>

                          <div className="text-wrapper-36">12.929</div>
                        </div>

                        <div className="frame-75">
                          <div className="frame-76">
                            <div className="text-wrapper-37">Excl. VAT</div>

                            <div className="text-wrapper-38">€ 10,500</div>
                          </div>

                          <div className="frame-76">
                            <div className="text-wrapper-37">VAT</div>

                            <div className="text-wrapper-38">€ 2,429</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="frame-70">
                      <div className="frame-71">
                        <img
                          className="frame-72"
                          alt="Frame"
                          src="/img/mobile/frame-41.svg"
                        />

                        <div className="text-wrapper-35">EXPENSES</div>
                      </div>

                      <div className="frame-73">
                        <div className="frame-74">
                          <div className="text-wrapper-36">€</div>

                          <div className="text-wrapper-36">12.929</div>
                        </div>

                        <div className="frame-75">
                          <div className="frame-76">
                            <div className="text-wrapper-37">Excl. VAT</div>

                            <div className="text-wrapper-38">€ 10,500</div>
                          </div>

                          <div className="frame-76">
                            <div className="text-wrapper-37">VAT</div>

                            <div className="text-wrapper-38">€ 2,429</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="overlap-group-6">
                    <div className="rectangle-8" />

                    <Frame
                      archiveClassName="frame-53"
                      archiveClassNameOverride="frame-58"
                      className="frame-48"
                      divClassName="frame-52"
                      divClassName1="frame-56"
                      divClassName2="frame-60"
                      divClassName3="frame-63"
                      divClassNameOverride="frame-54"
                      frame="/img/mobile/frame-1073714128-1.png"
                      frame1="/img/mobile/frame-1073714128-1.png"
                      frame2="/img/mobile/frame-1073714128-1.png"
                      frame3="/img/mobile/frame-1073714128-1.png"
                      frameClassName="frame-49"
                      frameClassName1="frame-54"
                      frameClassName2="frame-80"
                      frameClassName3="frame-81"
                      frameClassNameOverride="frame-77"
                      img="/img/mobile/frame-1073714128-1.png"
                      imgClassName="frame-78"
                      imgClassNameOverride="frame-79"
                      settingsClassName="frame-61"
                      transactionsClassName="frame-50"
                    />
                  </div>

                  <div className="frame-82">
                    <img
                      className="frame-83"
                      alt="Frame"
                      src="/img/mobile/frame-1073714143.svg"
                    />

                    <img
                      className="frame-83"
                      alt="Frame"
                      src="/img/mobile/frame-1073714141-1.svg"
                    />

                    <img
                      className="frame-23"
                      alt="Frame"
                      src="/img/mobile/frame-1073714162-2.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-14">
          <div className="overlay-shadow">
            <div className="container-12">
              <div className="text-wrapper-29">Features</div>
            </div>

            <img
              className="mask-group-4"
              alt="Mask group"
              src="/img/mobile/mask-group-4.svg"
            />
          </div>

          <p className="text-wrapper-39">The app that works for you</p>

          <p className="text-wrapper-40">
            With summie, all your receipts and invoices are in one place.
            Effortlessly add and attach your receipt directly to your
            transaction. summie makes it simple for every entrepreneur,
            freelancer, or individual who wants to streamline their financial
            administration.
          </p>
        </div>

        <div className="overlap-9">
          <p className="text-wrapper-41">
            summie started with a simple idea: what if we could make managing
            receipts easy? As entrepreneurs, we were tired of the paper clutter
            and existing inefficient tools. We knew we needed something better,
            not just for ourselves but for every professional or individual who
            values their time.
          </p>

          <p className="text-wrapper-42">
            summie started with a simple idea: what if we could make managing
            receipts easy? As entrepreneurs, we were tired
          </p>
        </div>

        <div className="background-2">
          <div className="container-wrapper">
            <div className="container-15">
              <div className="overlay-shadow">
                <div className="container-12">
                  <div className="text-wrapper-29">Result</div>
                </div>

                <img
                  className="mask-group-5"
                  alt="Mask group"
                  src="/img/mobile/mask-group-2.svg"
                />
              </div>

              <div className="container-16">
                <p className="text-wrapper-43">
                  Together, we combined our skills in business and technology to
                  create summie
                </p>

                <p className="text-wrapper-44">
                  – the app that saves both time and money. We dream of making
                  financial knowledge and solutions accessible for everyone and
                  easy to understand, and summie is our way to make that
                  difference.
                </p>
              </div>
            </div>
          </div>

          <div className="group-wrapper">
            <div className="group-3">
              <div className="overlap-10">
                <div className="group-4">
                  <div className="overlap-group-7">
                    <div className="text-wrapper-45">Modern Finance,</div>

                    <img
                      className="made-simple"
                      alt="Made simple"
                      src="/img/mobile/made-simple.svg"
                    />
                  </div>
                </div>

                <img className="group-5" alt="Group" src="/img/mobile/group.png" />

                <img
                  className="group-6"
                  alt="Group"
                  src="/img/mobile/group-1-2x.png"
                />

                <img className="group-7" alt="Group" src="/img/mobile/group-2.png" />

                <img className="group-8" alt="Group" src="/img/mobile/group-3.png" />

                <img
                  className="group-9"
                  alt="Group"
                  src="/img/mobile/group-4-2x.png"
                />

                <img
                  className="group-10"
                  alt="Group"
                  src="/img/mobile/group-5-2x.png"
                />

                <img
                  className="group-11"
                  alt="Group"
                  src="/img/mobile/group-6-2x.png"
                />

                <img
                  className="group-12"
                  alt="Group"
                  src="/img/mobile/group-7-2x.png"
                />

                <img
                  className="group-13"
                  alt="Group"
                  src="/img/mobile/group-8-2x.png"
                />

                <img
                  className="group-14"
                  alt="Group"
                  src="/img/mobile/group-9-2x.png"
                />

                <img
                  className="group-15"
                  alt="Group"
                  src="/img/mobile/group-12-2x.png"
                />

                <img
                  className="group-16"
                  alt="Group"
                  src="/img/mobile/group-13-2x.png"
                />

                <img
                  className="group-17"
                  alt="Group"
                  src="/img/mobile/group-14-2.png"
                />

                <img
                  className="clip-path-group"
                  alt="Clip path group"
                  src="/img/mobile/clip-path-group.png"
                />

                <img
                  className="group-18"
                  alt="Group"
                  src="/img/mobile/group-1073714129.png"
                />

                <div className="group-19" />

                <div className="group-20" />

                <div className="group-21" />

                <div className="text-wrapper-46">+25%</div>
              </div>

              <div className="overlap-11">
                <img
                  className="group-22"
                  alt="Group"
                  src="/img/mobile/frame-1073714128-1.png"
                />

                <img
                  className="group-23"
                  alt="Group"
                  src="/img/mobile/frame-1073714128-1.png"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-17">
          <div className="icon">
            <img className="union" alt="Union" src="/img/mobile/union.svg" />
          </div>

          <p className="upgrade-to-the-new">
            Upgrade to the new way
            <br />
            of managing expenses
          </p>

          <div className="follow">
            <div className="border" />

            <img className="SVG" alt="Svg" src="/img/mobile/svg-1.svg" />

            <div className="text-wrapper-28">Coming soon</div>
          </div>
        </div>

        <div className="follow-2">
          <a href="https://www.instagram.com/summie.app?igsh=dnAwdTg1cGhhM2g3" target="_blank" rel="noopener noreferrer">
           <img className="image" alt="Image" src="/img/mobile/image-7.png" />
          </a>
          <div className="text-wrapper-47">
           <a href="https://www.instagram.com/summie.app?igsh=dnAwdTg1cGhhM2g3" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>

        <div className="frame-84">
          <div className="div-wrapper-2">
            <div className="text-wrapper-48">Privacy Policy</div>
          </div>

          <div className="div-wrapper-2">
            <div className="text-wrapper-48">Terms of Conditions</div>
          </div>

          <div className="div-wrapper-2">
            <p className="text-wrapper-48">summie B.V. All rights reserved.</p>
          </div>
        </div>

        <div className="text-wrapper-49">
          <a href="mailto:hello@summie.co">hello@summie.co</a>
          </div>

        <img className="logo" alt="Logo" src="/img/mobile/logo-2.svg" />
      </div>
    </div>
  );
};
