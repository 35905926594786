/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame = ({
  className,
  frameClassName,
  transactionsClassName,
  frameClassNameOverride,
  frame = "/img/frame-1.svg",
  divClassName,
  archiveClassName,
  divClassNameOverride,
  imgClassName,
  img = "/img/frame-2.svg",
  divClassName1,
  imgClassNameOverride,
  frame1 = "/img/frame.svg",
  archiveClassNameOverride,
  frameClassName1,
  frameClassName2,
  frame2 = "/img/frame-3.svg",
  divClassName2,
  settingsClassName,
  frameClassName3,
  frame3 = "/img/frame-4.svg",
  divClassName3,
}) => {
  return (
    <div className={`frame ${className}`}>
      <div className={`transactions-wrapper ${frameClassName}`}>
        <div className={`transactions ${transactionsClassName}`}>
          <img
            className={`img-2 ${frameClassNameOverride}`}
            alt="Frame"
            src={frame}
          />

          <div className={`text-wrapper-5 ${divClassName}`}>Overview</div>
        </div>
      </div>

      <div className={`archive ${archiveClassName}`}>
        <div className={`div-2 ${divClassNameOverride}`}>
          <img className={`img-3 ${imgClassName}`} alt="Frame" src={img} />

          <div className={`text-wrapper-6 ${divClassName1}`}>Library</div>
        </div>
      </div>

      <img
        className={`img-4 ${imgClassNameOverride}`}
        alt="Frame"
        src={frame1}
      />

      <div className={`frame-wrapper ${archiveClassNameOverride}`}>
        <div className={`div-2 ${frameClassName1}`}>
          <img
            className={`img-3 ${frameClassName2}`}
            alt="Frame"
            src={frame2}
          />

          <div className={`text-wrapper-7 ${divClassName2}`}>Roadmap</div>
        </div>
      </div>

      <div className={`settings ${settingsClassName}`}>
        <img className={`img-5 ${frameClassName3}`} alt="Frame" src={frame3} />

        <div className={`text-wrapper-6 ${divClassName3}`}>More</div>
      </div>
    </div>
  );
};

Frame.propTypes = {
  frame: PropTypes.string,
  img: PropTypes.string,
  frame1: PropTypes.string,
  frame2: PropTypes.string,
  frame3: PropTypes.string,
};
