/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxLinearImport = ({ className }) => {
  return (
    <svg
      className={`vuesax-linear-import ${className}`}
      fill="none"
      height="9"
      viewBox="0 0 9 9"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.35742 4.6665L4.25837 5.56745L5.15932 4.6665"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5279"
      />

      <path
        className="path"
        d="M4.25781 1.96362V5.54279"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5279"
      />

      <path
        className="path"
        d="M7.11531 4.84229C7.11531 6.39783 6.05951 7.65775 4.29984 7.65775C2.54018 7.65775 1.48438 6.39783 1.48438 4.84229"
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.5279"
      />
    </svg>
  );
};
