/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldTickCircle = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-tick-circle ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16 2.66667C8.65333 2.66667 2.66667 8.65333 2.66667 16C2.66667 23.3467 8.65333 29.3333 16 29.3333C23.3467 29.3333 29.3333 23.3467 29.3333 16C29.3333 8.65333 23.3467 2.66667 16 2.66667ZM22.3733 12.9333L14.8133 20.4933C14.6267 20.68 14.3733 20.7867 14.1067 20.7867C13.84 20.7867 13.5867 20.68 13.4 20.4933L9.62667 16.72C9.24 16.3333 9.24 15.6933 9.62667 15.3067C10.0133 14.92 10.6533 14.92 11.04 15.3067L14.1067 18.3733L20.96 11.52C21.3467 11.1333 21.9867 11.1333 22.3733 11.52C22.76 11.9067 22.76 12.5333 22.3733 12.9333Z"
        fill="#23A26D"
      />

      <g className="g" opacity="0" />
    </svg>
  );
};
